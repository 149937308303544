#hero {
  padding: 70px 0;

  .hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 30px;

    &--text {
      h1 {
        font-weight: 700;
        font-size: 40px;
        color: #000;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        color: black;
        padding: 30px 0;
      }

      &__btn {
        display: flex;
        align-items: center;
        margin: 10px 0;
        filter: drop-shadow(2px 4px 8px #02897A);

        button {
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          padding: 15px 35px;
          background: #02897A;
          color: #FFFFFF;
          outline: none;
          transition: .5s;
          border-radius: 5px;

          &:hover {
            transition: .4s;
            color: #02897A;
            background: transparent;
            border: 2px solid #02897A;
          }
        }

        a {
          font-weight: 600;
          font-size: 18px;
          color: #02897A;
          text-decoration: underline #02897A;
          margin: 0 15px;
          transition: .5s;

          &:hover {
            color: rgba(0, 0, 0, 0.85);
          }

          &--play {
            color: #02897A;
          }
        }
      }
    }

    img {
      filter: drop-shadow(2px 4px 8px #02897A);
    }
  }
}
#management {
  padding: 40px 0;
  .management {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 50px;

    &--text {
      ul {
        font-weight: 600;
        font-size: 16px;
        color: #22343D;
      }

      &__accessory {
        padding-top: 10px;
      }

      &__alter {
        padding-top: 10px;
      }

      &__para {
        padding: 60px 0;
      }

      h2 {
        padding: 10px 0;
        font-weight: 700;
        font-size: 32px;
        color: #22343D;

      }

      p {
        font-weight: 400;
        font-size: 16px;
        color: #22343D;
        padding: 10px 0;
      }
    }
    img {
      filter: drop-shadow(2px 4px 8px #02897A);
    }
  }
}